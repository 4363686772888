import React, { lazy, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Spinner } from 'react-bootstrap';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';

import { route } from './RouteName';
import ProtectedRoute from './ProtectedRoute';
import { projectRoutes } from './projectRoutes';
import LoginApi from '../api/LoginApi';

// const App = lazy(() => import("./App"));
const AppManager = lazy(() => import('../components/AppManager/AppManager'));
const Settings = lazy(() => import('../pages/Settings'));
const Login = lazy(() => import('../pages/Login/Login'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const ResetPassword = lazy(() => import('../pages/ResetPassword/ResetPassword'));
const Authorize = lazy(() => import('../pages/Authorize/Authorize'));
const User = lazy(() => import('../pages/User/User'));
const SidebarSetup = lazy(() => import('../components/Sidebar/SidebarSetup'));
const Layout = lazy(() => import('../components/Layout/Layout'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function _Routes() {
	const dispatch = useDispatch();
	const isSuperUser = useSelector((state) => state.auth.superUser);

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (isAuthenticating) {
			const loginApi = new LoginApi();

			loginApi
				.checkToken({ token: Cookies.get('au0') })
				.then((res) => {
					if (res.data.active) {
						setIsLoggedIn(true);
					}
					if (res.data.is_superuser) {
						dispatch({type: "auth/setIsSuperUser", payload: res.data.is_superuser})
					}
					setIsAuthenticating(false);
				})
				.catch((err) => {
					setIsAuthenticating(false);
					if (
						![`/${route.login}`, `/${route.error}`].includes(location.pathname) &&
						!location.pathname.includes(`/${route.resetPassword}`)
					) {
						if ([401, 403].includes(err.response?.status)) {
							navigate('/login');
						} else {
							navigate('/error');
						}
					}
				});
		}
	}, [isAuthenticating, isLoggedIn, navigate, location, dispatch]);

	let html;
	if (isAuthenticating && isLoggedIn) {
		return (
			<div className='w-100 min-vw-100 h-100 min-vh-100 d-flex justify-content-center align-items-center'>
				<Spinner animation='border' role='status' variant='secondary'></Spinner>
			</div>
		);
	}
	if (!isAuthenticating && isLoggedIn) {
		html = (
			<>
				<Route
					path='*'
					element={<div className='w-100 min-vw-100 h-100 min-vh-100 d-flex justify-content-center align-items-center'>Page not found</div>}
				></Route>

				<Route path='/' element={<AppManager />} />

				<Route path={route.error} element={<ErrorPage />} />

				{/* Setting's route should be accessed only by superuser */}
				{isSuperUser &&
					<Route path={route.settings}>
						<Route index element={<Settings page={route.font} />} />
						<Route path={route.font} element={<Settings page={route.font} />} />
						<Route path={route.colors} element={<Settings page={route.colors} />} />
						<Route path={route.icons} element={<Settings page={route.icons} />} />
						<Route path={route.statuses} element={<Settings page={route.statuses} />} />
						<Route path={route.signTypeColor} element={<Settings page={route.signTypeColor} />} />
						<Route path={route.region} element={<Settings page={route.region} />} />
					</Route>
				}

				<Route path={route.openSign} element={<AppManager page='open-sign' />} />
				{projectRoutes.map((r) => {
					const isGroupsSuperUser = r.groups;
					return (
						(isSuperUser || (!isSuperUser && !isGroupsSuperUser)) &&
						<Route
							key={r.path}
							path={r.path}
							element={
								<Layout style={r.style || null}>
									<SidebarSetup />
									{r.element}
								</Layout>
							}
						/>
					);
				})}
				<Route path={route.dashboard} element={<Dashboard />} />

				{/* <Route path={route.projects}>
					<Route
						index
						element={
							<Layout style={{ minHeight: 'calc(100vh - 78px)', height: '100%' }}>
								<SidebarSetup />
								<ProjectStatus />
							</Layout>
						}
					/>
					<Route
						path=':view'
						element={
							<Layout style={{ minHeight: 'calc(100vh - 78px)', height: '100%' }}>
								<SidebarSetup />
								<ProjectStatus />
							</Layout>
						}
					/>
				</Route> */}

				<Route path={route.auth} element={<Authorize />} />
				<Route path={route.resetPassword} element={<ResetPassword />} />
				<Route path={route.user}>
					<Route path={route.customId} element={<User />}></Route>
				</Route>
			</>
		);
	}
	if (!isAuthenticating && !isLoggedIn) {
		html = (
			<>
				<Route path={route.error} element={<ErrorPage />} />
				<Route element={<ProtectedRoute redirectPath='/' isAllowed={!isLoggedIn} />}>
					<Route path={route.login} element={<Login setIsLoggedIn={setIsLoggedIn} setIsAuthenticating={setIsAuthenticating} />} />
				</Route>
				{/* <Route path={route.login} element={<Login setIsLoggedIn={setIsLoggedIn} />} /> */}
				<Route element={<ProtectedRoute redirectPath='/' isAllowed={!isLoggedIn} />}>
					<Route path={route.resetPassword} element={<ResetPassword />} />
				</Route>
			</>
		);
	}
	return <SentryRoutes>{html}</SentryRoutes>;
}

export default _Routes;
