import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CustomerApi from '../../api/CustomerApi';
import UserApi from '../../api/UserApi';

const customerApi = new CustomerApi();
const userApi = new UserApi();

export const getAllCustomers = createAsyncThunk('content/setAllCustomers', async () => {
	const response = await customerApi.getAllCustomers({ max_size: true, ordering: 'name' });
	return response.data;
});

export const getSignStatuses = createAsyncThunk('content/setSignStatuses', async (params) => {
	const response = await customerApi.getAllStatuses({ max_size: true, ordering: 'workflow_index' });
	return response.data;
});

export const getAllUsers = createAsyncThunk('content/setAllUsers', async () => {
	const response = await userApi.getUserInformation(null, { max_size: true, ordering: 'name' });
	return response.data;
});

const contentSlice = createSlice({
	name: 'content',
	initialState: {
		signStatuses: [],
		signActiveOrderStatuses: [],
		signProbabilityStatuses: [],
		signApprovedStatuses: [],
		draftStatus: {},
		systemUsers: [],
		systemUsersOptions: [],
		customers: null,
		customersOptions: null,
	},
	reducers: {
		setProbabilityStatus(state, action) {
			const currState = state;
			const currSignStatuses = currState.signStatuses;
			const allStatus = action.payload;

			const statuses = currSignStatuses
				?.filter((item) => allStatus.includes(item.name))
				?.map((s) => s.transitions)
				?.reduce((a, b) => {
					const x = a || [];
					return x.concat(b);
				}, []);

			const arrayUniqueByKey = statuses.filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

			currState.signProbabilityStatuses = arrayUniqueByKey;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSignStatuses.fulfilled, (state, action) => {
			const currState = state;
			const res = action.payload?.results;
			currState.signStatuses = res;
			currState.draftStatus = res.find((r) => r.id === 1);
			currState.signActiveOrderStatuses = res.filter((r) => [2, 4, 5, 15].includes(r.id));
			currState.signApprovedStatuses = res.filter((r) => [5, 15].includes(r.id));
		});
		builder.addCase(getAllUsers.fulfilled, (state, action) => {
			const currState = state;
			const res = action.payload?.results;
			currState.systemUsers = res;
			const optionUsers = res?.map((user) => {
				return { value: user.id, label: user.name };
			});
			currState.systemUsersOptions = optionUsers;
		});
		builder.addCase(getAllCustomers.fulfilled, (state, action) => {
			const currState = state;
			const res = action.payload?.results;
			currState.customers = res;
			const optionUsers = res?.map((c) => {
				return { id: c.id, value: c.id, label: c.name };
			});
			currState.customersOptions = optionUsers;
		});
	},
});

export const contentActions = contentSlice.actions;
export default contentSlice;
